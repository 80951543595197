<template>
  <b-card no-body>
    <div slot="header">
      <strong>{{ title }}</strong>
      <div class="card-header-actions">
        <slot name="action" />
      </div>
    </div>
    <b-card-body class="p-0">
      <slot name="filter" />

      <b-row>
        <b-col sm="12">
          <b-table
            ref="selectableTable"
            responsive
            show-empty
            :sticky-header="stickyHeader"
            :select-mode="selectMode"
            :fields="columns"
            :items="rows"
            :busy="isLoading"
            :hover="selectable"
            :selectable="selectable"
            :striped="selectable"
            @row-selected="onRowSelected"
          >
            <template #table-busy>
              <div class="text-center empty">
                <b-button variant="primary" disabled>
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </b-button>
              </div>
            </template>
            <template #empty>
              <div class="text-center p-5">
                <img
                  :src="require('@/assets/svg/empty.svg')"
                  height="200vh"
                  class="mb-10"
                />
                <slot name="emptymessage" />
              </div>
            </template>
            <template
              v-for="(_, slotName) of $scopedSlots"
              v-slot:[slotName]="scope"
            >
              <slot :name="slotName" v-bind="scope" />
            </template>
          </b-table>
        </b-col>
        <b-col sm="12" v-if="rows.length > 0 && pagination">
          <span class="m-3">
            Showing {{ showing }} of {{ totalRecords }} results
          </span>
          <b-pagination
            class="mx-3 mb-2 float-right"
            size="sm"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            v-model="pageNumber"
            @change="onPageChange"
            :total-rows="totalRecords"
            :per-page="pageSize"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { tableHeight } from "@/shared/utils";

export default {
  props: {
    title: String,
    columns: Array,
    rows: Array,
    pageNumber: Number,
    pageSize: Number,
    stickyHeader: {
      type: [Number, String],
      default: `${tableHeight()}px`,
    },
    selectMode: String,
    totalPage: {
      type: Number,
      default: 0,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: Number,
    selectable: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showing() {
      const self = this;
      let page = self.pageNumber - 1;
      return self.pageSize * page + self.rows.length;
    },
  },
  methods: {
    tableHeight,
    onRowSelected(params) {
      this.$emit("onRowSelected", params);
    },
    onPageChange(params) {
      this.$emit("onPageChange", params);
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
  },
};
</script>
