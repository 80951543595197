/* eslint-disable */

import router from "@/router";
import store from "@/store";
import getPageTitle from "@/shared/page-title";
import Message from "vue-m-message";

const Permission = {
  init() {
    const whiteList = ["/login"];

    router.beforeEach((to, from, next) => {
      document.title = getPageTitle(to.meta.label || to.name);
      let user = store.getters["auth/user"];

      if (store.getters["auth/isLoggedIn"]) {
        if (to.path == "/login") {
          next({ path: "/dashboard" });
        }

        if (user.role.id == 0) {
          next();
        } else if (user.role.id == 1) {
          const commonAccess = ["Dashboard", "Profile", "ChangePassword"];
          if (commonAccess.indexOf(to.name) >= 0) {
            next();
          } else {
            Message.warning({
              zIndex: 10000,
              message: `You don't have permission to access this page.`,
            });
            next({ name: "Dashboard" });
          }
        }

        next();
      } else {
        if (whiteList.indexOf(to.path) != -1) {
          next();
        } else {
          next(`/login`);
        }
      }
    });
  },
};

export default Permission;
