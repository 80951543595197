import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: () => import("@/containers/DefaultContainer"),
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/views/dashboard"),
        },
        {
          path: "profile",
          name: "Profile",
          component: () => import("@/views/Profile"),
        },
        {
          path: "change-password",
          name: "ChangePassword",
          component: () => import("@/views/ChangePassword"),
        },

        {
          path: "management",
          name: "Management",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          redirect: "/management/account/",
          children: [
            {
              path: "building",
              name: "Building",
              component: () => import("@/views/management/building"),
            },
            {
              path: "unit",
              name: "unit",
              component: () => import("@/views/management/unit"),
            },
            {
              path: "create",
              name: "UnitManagementCreate",
              meta: {
                label: "Create",
              },
              component: () => import("@/views/management/unit/Form"),
            },
            {
              path: "update/:id",
              name: "UnitManagementUpdate",
              meta: {
                label: "Update",
              },
              component: () => import("@/views/management/unit/Form"),
            },
            {
              path: "create",
              name: "BuildingManagementCreate",
              meta: {
                label: "Create",
              },
              component: () => import("@/views/management/building/Form"),
            },
            {
              path: "update/:id",
              name: "BuildingManagementUpdate",
              meta: {
                label: "Update",
              },
              component: () => import("@/views/management/building/Form"),
            },
            {
              path: "meter",
              name: "MeterManagement",
              meta: {
                label: "Meter Management",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/management/meter/",
              children: [
                {
                  path: "/",
                  name: "MeterManagementTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/management/meter"),
                },
                {
                  path: "create",
                  name: "MeterManagementCreate",
                  meta: {
                    label: "Create",
                  },
                  component: () => import("@/views/management/meter/Form"),
                },
                {
                  path: "update/:id",
                  name: "MeterManagementUpdate",
                  meta: {
                    label: "Update",
                  },
                  component: () => import("@/views/management/meter/Form"),
                },
              ],
            },
            {
              path: "account",
              name: "AccountManagement",
              meta: {
                label: "Account Management",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/management/account/",
              children: [
                {
                  path: "/",
                  name: "AccountManagementTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/management/account"),
                },
                {
                  path: "create",
                  name: "AccountManagementCreate",
                  meta: {
                    label: "Create",
                  },
                  component: () => import("@/views/management/account/Form"),
                },
                {
                  path: "update/:id",
                  name: "AccountManagementUpdate",
                  meta: {
                    label: "Update",
                  },
                  component: () => import("@/views/management/account/Form"),
                },
              ],
            },
          ],
        },

        {
          path: "transaction",
          name: "Transaction",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          redirect: "/transaction/meter-readings/",
          children: [
            {
              path: "meter-readings",
              name: "MeterReadings",
              meta: {
                label: "Meter Readings",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/transaction/meter-readings/",
              children: [
                {
                  path: "/",
                  name: "MeterReadingsTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/transaction/meter-readings"),
                },
                {
                  path: "import",
                  name: "MeterReadingsImport",
                  meta: {
                    label: "Import",
                  },
                  component: () =>
                    import("@/views/transaction/meter-readings/Form"),
                },
                {
                  path: "history/:id",
                  name: "MeterReadingsHistory",
                  meta: {
                    label: "History",
                  },
                  component: () =>
                    import("@/views/transaction/meter-readings/History"),
                },
                {
                  path: "delete-all",
                  name: "MeterReadingsDeleteAll",
                  meta: {
                    label: "Delete All",
                  },
                  component: () =>
                    import("@/views/transaction/meter-readings/DeleteMeter"),
                },
              ],
            },
            {
              path: "adjustments",
              name: "Adjustments",
              component: () => import("@/views/transaction/adjustments"),
            },
            {
              path: "discount",
              name: "Discount",
              component: () => import("@/views/transaction/Discount"),
            },
            {
              path: "invoices",
              name: "Invoices",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/transaction/invoices/",
              children: [
                {
                  path: "/",
                  name: "InvoicesTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/transaction/invoices"),
                },
                {
                  path: "send-all",
                  name: "SendAll",
                  meta: {
                    label: "Send All",
                  },
                  component: () =>
                    import("@/views/transaction/invoices/SendAll"),
                },
                {
                  path: "delete-all",
                  name: "DeleteAllInvoice",
                  meta: {
                    label: "Delete All",
                  },
                  component: () =>
                    import("@/views/transaction/invoices/DeleteInvoice"),
                },
                {
                  path: "adjustment",
                  name: "InvoiceAdjustment",
                  meta: {
                    label: "Invoice Adjustment",
                  },
                  component: () =>
                    import("@/views/transaction/invoices/Adjustment"),
                },
                {
                  path: "adjustment/update/:id",
                  name: "InvoiceAdjustmentUpdate",
                  meta: {
                    label: "Update",
                  },
                  component: () => import("@/views/transaction/invoices/Adjustment"),
                },
                {
                  path: "save-one-pdf",
                  name: "SaveOnePdf",
                  meta: {
                    label: "Save All To One PDF",
                  },
                  component: () =>
                    import("@/views/transaction/invoices/SaveOnePdf"),
                },
                {
                  path: "generate",
                  name: "InvoicesGenerate",
                  meta: {
                    label: "Generate Invoice Data",
                  },
                  component: () =>
                    import("@/views/transaction/invoices/Generate"),
                },
                {
                  path: "history/:id",
                  name: "InvoicesHistory",
                  meta: {
                    label: "History",
                  },
                  component: () =>
                    import("@/views/transaction/invoices/history"),
                },
              ],
            },
            {
              path: "payments",
              name: "Payments",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/transaction/payments/",
              children: [
                {
                  path: "/",
                  name: "PaymentsTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/transaction/payments"),
                },
                {
                  path: "create",
                  name: "PaymentsCreate",
                  meta: {
                    label: "Create",
                  },
                  component: () => import("@/views/transaction/payments/form"),
                },
                {
                  path: "update/:id",
                  name: "PaymentsUpdate",
                  meta: {
                    label: "Update",
                  },
                  component: () => import("@/views/transaction/payments/form"),
                },
              ],
            },
          ],
        },
        {
          path: "email-template",
          name: "EmailTemplate",
          meta: {
            label: "Email Template",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/",
              name: "EmailTemplateList",
              meta: {
                label: "List",
              },
              component: () => import("@/views/email-template"),
            },
            {
              path: "create",
              name: "EmailTemplateCreate",
              meta: {
                label: "Create",
              },
              component: () => import("@/views/email-template/Form"),
            },
            {
              path: "circular",
              name: "EmailTemplateCircular",
              meta: {
                label: "Circular",
              },
              component: () => import("@/views/email-template/Circular"),
            },
            {
              path: "update/:id",
              name: "EmailTemplateUpdate",
              meta: {
                label: "Update",
              },
              component: () => import("@/views/email-template/Form"),
            },

          ],
        },
        {
          path: "email-reminder",
          name: "EmailReminder",
          meta: {
            label: "Email Reminder",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/",
              name: "EmailReminder",
              meta: {
                label: "List",
              },
              component: () => import("@/views/email-template/email-reminder"),
            }
          ],
        },

        {
          path: "report",
          name: "Report",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          redirect: "/report/debtor-aging/",
          children: [
            {
              path: "debtor-aging",
              name: "DebtorAging",
              meta: {
                label: "Debtor Aging",
              },
              component: () => import("@/views/report/debtor-aging"),
            },
            {
              path: "monthly-account-details",
              name: "MonthlyAccountDetails",
              meta: {
                label: "Monthly Account Details",
              },
              component: () => import("@/views/report/monthly-account-details"),
            },
            {
              path: "account-statements",
              name: "AccountStatements",
              meta: {
                label: "Account Statements",
              },
              component: () => import("@/views/report/account-statements"),
            },
            {
              path: "non-payment",
              name: "NonPayment",
              meta: {
                label: "Non Payment",
              },
              component: () => import("@/views/report/NonPayment"),
            },
            {
              path: "monthly-charges",
              name: "MonthlyCharges",
              meta: {
                label: "Monthly Charges",
              },
              component: () => import("@/views/report/MonthlyCharges"),
            },
          ],
        },

        {
          path: "setting",
          name: "Setting",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          redirect: "/setting/user-management/",
          children: [
            {
              path: "configuration",
              name: "Configuration",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/setting/configuration/",
              children: [
                {
                  path: "/",
                  name: "ConfigurationTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/setting/configuration"),
                },
                {
                  path: "update/:id",
                  name: "ConfigurationUpdate",
                  meta: {
                    label: "Update",
                  },
                  component: () => import("@/views/setting/configuration/Form"),
                },
              ],
            },
            {
              path: "user-management",
              name: "UserManagement",
              meta: {
                label: "User Management",
              },
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              redirect: "/setting/user-management/",
              children: [
                {
                  path: "/",
                  name: "UserManagementTable",
                  meta: {
                    label: "List",
                  },
                  component: () => import("@/views/setting/user-management"),
                },
                {
                  path: "create",
                  name: "UserManagementCreate",
                  meta: {
                    label: "Create",
                  },
                  component: () =>
                    import("@/views/setting/user-management/Form"),
                },
                {
                  path: "update/:id",
                  name: "UserManagementUpdate",
                  meta: {
                    label: "Update",
                  },
                  component: () =>
                    import("@/views/setting/user-management/Form"),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login"),
    },
    /* {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () => import("@/views/ForgotPassword"),
    }, */
  ],
});
