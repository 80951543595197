<template>
  <b-card no-body>
    <div slot="header">
      <strong>{{ title }}</strong>
      <div class="card-header-actions">
        <slot name="action" />
      </div>
    </div>
    <b-card-body class="p-0">
      <b-row>
        <b-col sm="12">
          <slot name="body" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>