import moment from "moment";
import accounting from "accounting";

export function getDate(format = "YYYY-MM-DD") {
  moment.locale("en-sg");
  return moment().format(format);
}

export function dateFormat(date, format) {
  moment.locale("en-sg");
  format = format || "DD/MM/YYYY";
  return moment(date).format(format);
}

export function dateTimeFormat(date, format) {
  moment.locale("en-sg");
  format = format || "DD/MM/YYYY HH:mm";
  return moment(date).format(format);
}

export function parseMonth(params) {
  let month = params.length == 1 ? `0${params}` : params;
  return month;
}

export function numberFormat(number, precision = 2, delimiter = ",") {
  let result = null;
  if (Math.floor(number) === number) {
    result = accounting.formatNumber(
      number,
      0,
      delimiter == "." ? "." : ",",
      delimiter == "." ? "," : "."
    );
  } else {
    result = accounting.formatNumber(
      number,
      precision,
      delimiter == "." ? "." : ",",
      delimiter == "." ? "," : "."
    );
  }

  return result;
}

export function tableHeight(gap = 360) {
  return document.documentElement.clientHeight - gap;
}

export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
