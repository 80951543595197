/* jshint esversion:6 */

const title = 'MCST 4560';

export default function getPageTitle(key) {
    const hasKey = key;
    if (hasKey) {
        const pageName = key;
        return `${pageName} - ${title}`;
    }
    return `${title}`;
}
