// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "core-js/es6/promise";
import "core-js/es6/string";
import "core-js/es7/array";

import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router";
import store from "./store";
import Backend from "./backend";
import Permission from "./permission";
import Treeselect from "@riophae/vue-treeselect";
import VuejsDialog from "vuejs-dialog";
import Loading from "vue-loading-overlay";
import Message from "vue-m-message";
import VueNumericInput from "vue-numeric-input";
import Vuelidate from "vuelidate";
import VuelidateErrorExtractor from "vuelidate-error-extractor";
import CustomFormGroup from "@/components/common/FormGroup.vue";
import CardTable from "@/components/common/CardTable";
import CardBase from "@/components/common/CardBase";

import "vuejs-dialog/dist/vuejs-dialog.min.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-m-message/dist/index.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

Vue.use(BootstrapVue);
Vue.use(VuejsDialog);
Vue.use(Loading, {
  loader: "dots",
  container: null,
  // color: "#003B5A",
  zIndex: 10001,
});
Vue.use(Message);
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: false,
  template: CustomFormGroup,
  messages: {
    required: "{attribute} must not be empty",
    url: "{attribute} is not a valid URL format",
    email: "{attribute} is not a valid Email address",
    minLength: "{attribute} must have at least {min} letters",
    maxLength: "{attribute} must have at most {max} letters",
    minValue: "{attribute} must be greather than {min}",
    isEmailAvailable:
      "{attribute} is not available. Must be at least 10 characters long.",
    sameAsNewPassword: "{attribute} doesn't match with new password",
    numeric: "{attribute} must be numeric",
  },
});
Vue.use(Treeselect);
Vue.use(VueNumericInput);

Vue.component("treeselect", Treeselect);
Vue.component("CardTable", CardTable);
Vue.component("CardBase", CardBase);

Backend.init();
Permission.init();

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: {
    App,
  },
});
