/* jshint esversion: 6 */

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// import account from './modules/account';
import apis from "./modules/apis";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["auth.user", "auth.token", "auth.config"],
    }),
  ],
  modules: {
    apis,
    auth,
  },
});
