<template>
  <b-form-group
    v-if="useHorizontal"
    label-cols-sm="4"
    content-cols-lg="8"
    :label="useLabel ? label : ''"
    :description="description"
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
  >
    <slot
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
  </b-form-group>
  <b-form-group
    v-else
    :label="useLabel ? label : ''"
    :description="description"
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
  >
    <slot
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  name: "FormElement",
  extends: singleErrorExtractorMixin,
  props: {
    useLabel: {
      type: Boolean,
      default: true,
    },
    useHorizontal: {
      type: Boolean,
      default: true,
    },
    description: String,
  },
};
</script>